import { CartContent } from '../../typings/base'
import { ACTIONS } from '../actions/cart'

export interface CartReducerState {
  loading: boolean
  parcelId?: string
  sizes?: any
  shipping_methods?: any
  status?: string
  rmsParcelShopId?: string
  isUpdated?: boolean
  address_type?: string
  error?: {
    message: string
  } | null
  item?: CartContent
  funnelStep?: number
}

export const initialProductsState: CartReducerState = {
  loading: false,
  funnelStep: 0,
}

const cartReducer = (
  state = initialProductsState,
  action
): CartReducerState => {
  switch (action.type) {
    case 'CREATE_CART_REQUEST':
    case 'DELETE_CART_REQUEST':
    case 'CREATE_SUBSCRIPTION_CART_REQUEST':
    case 'PATCH_CART_DATES_REQUEST':
    case 'ADD_PRODUCT_REQUEST':
    case 'ADD_MULTIPLE_PRODUCT_REQUEST':
    case 'PUT_PRODUCT_REQUEST':
    case 'DELETE_PRODUCT_REQUEST':
    case 'DELETE_PRODUCT_SECONDHAND_REQUEST':
    case 'PUT_CHECKOUT_PAYMENT_REQUEST':
    case 'PUT_CHECKOUT_SHIPPING_REQUEST':
    case 'PUT_CHECKOUT_ADDRESS_REQUEST':
    case 'PUT_CART_COUPON_REQUEST':
    case 'GET_CHECKOUT_SHIPPING_METHODS_REQUEST':
    case 'GET_CHECKOUT_REQUEST':
    case 'UPDATE_CART_SUBSCRIPTION_REQUEST':
    case 'GET_CART_REQUEST':
      return { ...state, loading: true }
    case 'PUT_CHECKOUT_ADDRESS_SUCCESS':
    case 'PUT_CHECKOUT_PAYMENT_SUCCESS':
    case 'PUT_CHECKOUT_SHIPPING_SUCCESS':
      return { ...state, loading: false }
    case 'ADD_MULTIPLE_PRODUCT_SUCCESS':
    case 'ADD_PRODUCT_SUCCESS':
    case 'CREATE_CART_SUCCESS':
    case 'CREATE_SUBSCRIPTION_CART_SUCCESS':
    case 'PUT_PRODUCT_SUCCESS':
    case 'DELETE_PRODUCT_SUCCESS':
    case 'DELETE_PRODUCT_SECONDHAND_SUCCESS':
    case 'GET_CHECKOUT_SUCCESS':
    case 'PUT_CART_COUPON_SUCCESS':
    case 'GET_CART_SUCCESS':
    case 'PATCH_CART_DATES_SUCCESS':
      return {
        ...state,
        loading: false,
        item: action.cart,
        status: 'cart live',
        error: null,
      }
    case 'UPDATE_CART_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        loading: false,
        item: action.cart?.cart,
        status: 'cart live',
        isUpdated: true,
        error: null,
      }
    case 'CREATE_CART_FAILURE':
    case 'CREATE_SUBSCRIPTION_CART_FAILURE':
    case 'DELETE_CART_FAILURE':
    case 'ADD_PRODUCT_FAILURE':
    case 'ADD_MULTIPLE_PRODUCT_FAILURE':
    case 'PUT_CHECKOUT_PAYMENT_FAILURE':
    case 'PUT_CHECKOUT_SHIPPING_FAILURE':
    case 'PUT_CART_COUPON_FAILURE':
    case 'PUT_PRODUCT_FAILURE':
    case 'DELETE_PRODUCT_FAILURE':
    case 'DELETE_PRODUCT_SECONDHAND_FAILURE':
    case 'PUT_CHECKOUT_ADDRESS_FAILURE':
    case 'UPDATE_CART_SUBSCRIPTION_FAILURE':
    case 'GET_CHECKOUT_FAILURE':
    case 'PATCH_CART_DATES_FAILURE':
    case 'GET_CART_FAILURE':
      return { ...state, loading: false, error: action.error, status: 'error' }
    case 'DELETE_CART_SUCCESS':
    case 'POST_CHECKOUT_SUCCESS':
      return {
        ...state,
        loading: false,
        item: undefined,
        status: 'cart deleted',
        address_type: '',
        error: null,
        parcelId: '',
      }
    case 'DELETE_CART_DP_SUCCESS':
      return {
        ...state,
        loading: false,
        item: undefined,
        status: 'cart deleted for DP',
        address_type: '',
        error: null,
        parcelId: '',
      }
    case 'GET_CHECKOUT_SHIPPING_METHODS_SUCCESS':
      return {
        ...state,
        loading: false,
        shipping_methods: action.shippingMethods,
      }
    case 'SET_ADDRESS_TYPE_CART':
      return { ...state, address_type: action.type_name }
    case 'SET_PARCEL_ID_CART':
      return { ...state, rmsParcelShopId: action.rmsParcelShopId }
    case 'EMPTY_CART':
      return { loading: false }
    case ACTIONS.SET_FUNNEL_STEP:
      return { ...state, funnelStep: action.step }
    default:
      return state
  }
}

export default cartReducer
